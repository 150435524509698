const { hubUrl, contractAddress, networkChainIds } =
    process.env.NODE_ENV === "production" ? {
        hubUrl: "/dogehub",
        contractAddress: "0x4206931337dc273a630d328da6441786bfad668f",
        networkChainIds: {
            Ethereum: 1,
            Binance: 56
        }
    } : process.env.NODE_ENV === "development" ? {
        hubUrl: "https://doge.gay/dogehub",
        contractAddress: "0x4206931337dc273a630d328da6441786bfad668f",
        networkChainIds: {
            Ethereum: 1,
            Binance: 56
        }
    } : process.env.NODE_ENV === "local" ? {
        hubUrl: "https://localhost:5001/dogehub",
        contractAddress: "0x5FbDB2315678afecb367f032d93F642f64180aa3",
        networkChainIds: {
            Ethereum: 31337,
            Binance: 56
        }
    } : process.env.NODE_ENV === "local-hub" ? {
        hubUrl: "https://localhost:5001/dogehub",
        contractAddress: "0x4206931337dc273a630d328da6441786bfad668f",
        networkChainIds: {
            Ethereum: 1,
            Binance: 56
        }
    } : {};
const infuraId = "b45eadaefd994c0abbf4a2f564dbe663";

const networkByChainId = {};
Object.keys(networkChainIds).forEach(x => networkByChainId[networkChainIds[x]] = x);

const currencySymbols = {
    Ethereum: "ETH",
    Binance: "BNB",
    Dogecoin: "DOGE"
}
Object.keys(currencySymbols).map(x => networkChainIds[x]).filter(x => x).forEach(x => currencySymbols[x] = currencySymbols[networkByChainId[x]]);

const gasPrices = {
//    Ethereum: "15000000000"
}
Object.keys(gasPrices).forEach(x => gasPrices[networkChainIds[x]] = gasPrices[x]);

function getContractExplorerUrl(networkOrChainId) {
    return isBinance(networkOrChainId) ?
        `https://bscscan.com/token/${contractAddress}` :
        `https://etherscan.io/token/${contractAddress}`;
}

function isBinance(networkOrChainId) { return networkOrChainId === networkChainIds["Binance"] || networkOrChainId === "Binance"; }

export {
    hubUrl,
    contractAddress,
    networkChainIds,
    networkByChainId,
    currencySymbols,
    infuraId,
    gasPrices,
    getContractExplorerUrl,
    isBinance
}