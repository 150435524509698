<template>
    <div class="app" :inert="modalIsOpen" :aria-hidden="modalIsOpen">
        <TheHeader></TheHeader>
        <TheWalletBanner></TheWalletBanner>
        <TheStarterButtons class="app-main"></TheStarterButtons>
        <TheStatsBanner></TheStatsBanner>
        <TheIntro></TheIntro>
        <TheHistory></TheHistory>
        <TheStats></TheStats>
        <TheFooter></TheFooter>
    </div>
    <TheConnectWallet v-if="showConnectWallet"></TheConnectWallet>
    <TheSignIn v-if="showSignIn"></TheSignIn>
    <TheSendFromDogecoin v-if="showSendFromDogecoin"></TheSendFromDogecoin>
    <TheSendFromEthereum v-if="showSendFromEthereum"></TheSendFromEthereum>
    <TheSendFromBridge v-if="showSendFromBridge" :network="sendFromBridgeTarget"></TheSendFromBridge>
    <TheXPub v-if="showXPub"></TheXPub>
    <TheGlobalUnminted v-if="showGlobalUnminted"></TheGlobalUnminted>
    <TheToaster></TheToaster>
</template>

<script>
    import TheHeader from "./components/TheHeader"
    import TheWalletBanner from "./components/TheWalletBanner"
    import TheStarterButtons from "./components/TheStarterButtons"
    import TheIntro from "./components/TheIntro"
    import TheStatsBanner from "./components/TheStatsBanner"
    import TheHistory from "./components/TheHistory"
    import TheStats from "./components/TheStats"
    import TheFooter from "./components/TheFooter"

    import TheConnectWallet from "./components/Modals/TheConnectWallet"
    import TheSignIn from "./components/Modals/TheSignIn"
    import TheSendFromDogecoin from "./components/Modals/TheSendFromDogecoin"
    import TheSendFromEthereum from "./components/Modals/TheSendFromEthereum"
    import TheSendFromBridge from "./components/Modals/TheSendFromBridge"
    import TheXPub from "./components/Modals/TheXPub"
    import TheGlobalUnminted from "./components/Modals/TheGlobalUnminted"

    import TheToaster from "./components/Layouts/TheToaster"

    import { connectionActions, walletActions } from "./store/action-types"
    import modals from "./modals"
    import { mapState } from "vuex"

    export default {
        computed: {
            ...mapState("app", {
                showConnectWallet: state => state.showModal == modals.connectWallet,
                showSignIn: state => state.showModal == modals.signIn,
                showSendFromDogecoin: state => state.showModal == modals.sendFromDogecoin,
                showSendFromEthereum: state => state.showModal == modals.sendFromEthereum,
                showSendFromBridge: state => state.showModal == modals.sendFromBridge,
                showXPub: state => state.showModal == modals.xPub,
                showGlobalUnminted: state => state.showModal == modals.globalUnminted,
                modalIsOpen: state => !!state.showModal,
                sendFromBridgeTarget: state => state.sendFromBridgeTarget
            })
        },
        components: {
            TheHeader,
            TheWalletBanner,
            TheStarterButtons,
            TheIntro,
            TheStatsBanner,
            TheHistory,
            TheStats,
            TheFooter,
            TheConnectWallet,
            TheSignIn,
            TheSendFromDogecoin,
            TheSendFromEthereum,
            TheSendFromBridge,
            TheXPub,
            TheToaster,
            TheGlobalUnminted
        },
        created() {
            this.$store.dispatch(`connection/${connectionActions.maintainConnection}`);
            this.$store.dispatch(`wallet/${walletActions.init}`);            
        }
    }
</script>

<style>
    @import url("./assets/bootstrap-reboot.css");

    :root {
        --orange: #f2a51f;
        --orange-dark-1: #e2950f;
        --white: #ffffea;
        --offwhite: #f2ede0;
        --beige: #ecd592;
        --darkblue: #1e4151;
        --darkblue-dark-1: #0e3141;
        --darkgray: #333;
        --green: #76c421;
        --green-dark-1: #65b311;
        --green-dark-2: #54a301;
        --lightblue: #019cde;
        --red: #ec3f01;
        --yellow: #f0ec01;
        --pink: #ff69b0;
    }

    body {
        font-family: 'Comic Sans MS', 'Comic Sans';
        background: var(--orange);
        color: var(--white);
        margin: 0;
    }

    h1, h2, h3 {
        margin: 0;
    }

    h1 {
        font-size: 2.5em;
    }

    h2 {
        font-size: 2em;
    }

    h3 {
        font-size: 1.75em;
    }

    input {
        width: 100%;
    }

    button {
        appearance: none;
        outline: none;
        border-radius: 0.5em;
        border: 0.125em solid var(--green-dark-2);
        background-color: var(--green);
        color: var(--white);
        line-height: 1;
        padding: 0.25em;
    }

        button:focus {
            outline: none;
        }

        button:active {
            transform: translateY(1px);
            transition: 0.075s;
        }

        button:hover:enabled, button:focus:enabled {
            background-color: var(--green-dark-1);
        }

        button:disabled {
            background-color: #d9534f;
            opacity: 0.5;
        }

        button.larger {
            padding: 1em;
        }
        button.minimal {
            padding: 0;
            border-radius: 0;
        }

    .Clickable {
        cursor: pointer;
    }

    .Padded {
        padding: 1em;
    }

    .Address {
        font-size: 0.75em;
        word-break: break-all;
        user-select: all;
    }

    .Error {
        color: var(--red);
    }

    .QR {
        background-color: var(--beige);
        padding: 1.5em;
        border-radius: 1em;
    }

    .ControlPanel {
        width: 25em;
        text-align: center;
        border: 0.25em solid var(--darkblue);
        border-radius: 1em;
        overflow: hidden;
        background: var(--orange);
        position: relative;
    }

        .ControlPanel h2 {
            color: var(--darkblue);
        }

    a.White, a.White:visited {
        color: var(--white);
    }

    @media (max-width: 63em) {
        .ControlPanel {
            width: 25em;
        }
    }

    @media (max-width: 25em) {
        .ControlPanel {
            width: 100%;
        }
    }
</style>

<style scoped>
    .app {
        display: flex;
        flex-flow: column;
        min-height: 100vh;
    }

    .app-main {
        flex-grow: 1;
    }
</style>
