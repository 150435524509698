<template>
    <div class="contract">
        <a :href="contractExplorerUrl" target="_blank" class="White Address">Contract</a>
        <span class="Address">: </span>
        <span class="Address">{{ contractAddress }}</span>
    </div>
    <section v-if="hasGlobalStats" class="stats-banner">
        <div>Total circulating on Ethereum: {{ ethereumSupply }} DOGE</div>
        <div>Total circulating on Binance: {{ binanceSupply }} DOGE</div>
    </section>
</template>

<script>
    import { getContractExplorerUrl, contractAddress } from "../env"
    import { mapState } from "vuex";
    import { choppedAddress, friendlyAmount } from "../js/helpers"

    export default {
        data() {
            return {
                contractAddress
            }
        },
        computed: {
            ...mapState("app", {
                hasGlobalStats: state => !!state.globalStats.xPub,
                unclaimedBalances: state => friendlyAmount(state.globalStats.unclaimedBalances, 0),
                ethereumSupply: state => friendlyAmount(state.globalStats.networkStats["ethereum"].totalSupply, 0),
                binanceSupply: state => friendlyAmount(state.globalStats.networkStats["binance"].totalSupply, 0)
            }),
            ...mapState("wallet", {
                chainId: state => state.chainId
            }),
            contractExplorerUrl() { return getContractExplorerUrl(this.chainId); }
        },
        methods: {
            choppedAddress
        }
    }
</script>

<style scoped>
    .contract {
        text-align: center;
        line-height: 1;
        padding-bottom: 0.125em;
    }
    .stats-banner {
        background-color: var(--darkblue-dark-1);
        font-size: 0.75em;
        display: grid;
        justify-items: center;
    }
</style>