<template>
    <div class="starter-buttons">
        <div>
            <button class="larger" @click="dogeToEthereum">
                Send from Dogecoin Blockchain
            </button>
            <button class="larger" @click="ethereumToDoge">
                Send from {{ network }} Blockchain
            </button>
        </div>
        <div v-if="hasBridgeBalance">
            <ValuePanel :value="friendlyAmount(bridgeBalance)" units="DOGE" :inline="true">Bridge Balance</ValuePanel>
        </div>
        <div v-if="hasBridgeBalance">
            <button class="larger" @click="bridgeToDoge">
                Send to Dogecoin Blockchain
            </button>
            <button class="larger" @click="bridgeToEthereum">
                Send to Ethereum Blockchain
            </button>
            <button class="larger" @click="bridgeToBinance">
                Send to Binance Blockchain
            </button>
        </div>
        <div v-if="anyUnminted">
            <div v-for="send in unminted" :key="send.instructionId" class="unminted">
                <div>{{ friendlyAmount(send.amountToSend) }} DOGE on {{ send.networkName }}</div>
                <div class="Address">{{ send.destinationAddress }}</div>
                <button class="unminted-mint" @click="mint(send)" :disabled="!!waiting(send) || send.networkName != network">Mint Now</button>
                <div v-if="waiting(send)">{{ minting[send.instructionId].waiting }}</div>
                <div v-if="error(send)" class="Error">{{ minting[send.instructionId].error }}</div>
                <div v-if="send.networkName != network" class="Error">Must be connected to {{ send.networkName }}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex"
    import { appActions } from "../store/action-types"
    import ValuePanel from "./Widgets/ValuePanel"
    import { friendlyAmount } from "../js/helpers"
    import { friendlyError } from "../store/errorHelper"

    export default {
        components: {
            ValuePanel
        },
        data() {
            return {
                minting: {},
                successfullyMinted: {}
            }
        },
        computed: {
            ...mapState("app", {
                bridgeBalance: state => state.hasSessionData ? state.sessionData.balance : "0",
                allUnminted: state => state.hasSessionData ? state.sessionData.sendInstructions.filter(x => !x.transactionHash && x.mintSignature) : []
            }),
            ...mapState("wallet", {
                rawNetwork: state => state.network
            }),
            network() { return this.rawNetwork || "Ethereum"; },
            hasBridgeBalance() { return this.bridgeBalance > 0; },
            unminted() { return this.allUnminted.filter(x => !this.successfullyMinted[x.instructionId]); },
            anyUnminted() { return this.unminted.length > 0; }            
        },
        methods: {
            friendlyAmount,
            async dogeToEthereum() {
                await this.$store.dispatch(`app/${appActions.openSendFromDogecoin}`, null, { root: true });
            },
            async ethereumToDoge() {
                await this.$store.dispatch(`app/${appActions.openSendFromEthereum}`, null, { root: true });
            },
            async bridgeToDoge() {
                await this.$store.dispatch(`app/${appActions.openSendToDogecoin}`, null, { root: true });
            },
            async bridgeToEthereum() {
                await this.$store.dispatch(`app/${appActions.openSendToEthereum}`, null, { root: true });
            },
            async bridgeToBinance() {
                await this.$store.dispatch(`app/${appActions.openSendToBinance}`, null, { root: true });
            },
            async mint(sendInstruction) {
                this.minting[sendInstruction.instructionId] = {
                    waiting: "Waiting for signature confirmation from your wallet..."
                };
                try {
                    await this.$store.dispatch(`app/${appActions.mint}`, sendInstruction, { root: true });
                    this.successfullyMinted[sendInstruction.instructionId] = true;
                }
                catch (e) {
                    this.minting[sendInstruction.instructionId].error = friendlyError(e, "Failed to create the minting transaction");                    
                }
                this.minting[sendInstruction.instructionId].waiting = "";
            },
            waiting(sendInstruction) { return (this.minting[sendInstruction.instructionId] || {}).waiting; },
            error(sendInstruction) { return (this.minting[sendInstruction.instructionId] || {}).error; }
        }
    }
</script>

<style scoped>
    .starter-buttons {
        text-align: center;
        display: grid;
        align-content: center;
    }

        .starter-buttons .larger {
            margin: 1em;
        }

    .unminted {
        display: inline-block;       
        border: 2px solid var(--darkblue);
        background: var(--orange-dark-1);
        padding: 1em;
        margin-bottom: 1em;
    }

    .unminted-mint {
        margin-top: 1em;
    }
</style>