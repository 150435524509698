<template>
    <header class="toast-header">
        <span v-if="!fulfilled">Send in Progress</span>
        <span v-else-if="aborted">Send Cancelled &amp; Refunded</span>
        <span v-else-if="unminted">Ready to Mint</span>
        <span v-else-if="complete">Send Complete</span>
    </header>
    <div>{{ friendlyAmount(data.amountToSend) }} DOGE</div>
    <div class="Address">{{ data.destinationAddress }}</div>
    <div>Via {{ data.networkName }} blockchain</div>
    <div v-if="aborted">{{ data.abortReason }}</div>
    <div v-else-if="complete"><a :href="explorerUrl" target="_blank">View Transaction</a></div>
</template>

<script>
    import { friendlyAmount, explorerUrl } from "../../js/helpers"

    export default {
        props: {
            data: Object
        },
        computed: {
            explorerUrl() {
                return explorerUrl(this.data.transactionHash, this.data.networkName);
            },
            fulfilled() { return !!this.data.fulfillmentDate; },
            aborted() { return !!this.data.abortReason; },
            complete() { return !!this.data.transactionHash; },
            unminted() { return this.data.mintSignature && !this.data.transactionHash; }
        },
        methods: {
            friendlyAmount
        }
    }
</script>
