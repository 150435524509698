<template>
    <div v-if="anyToasts" class="toaster">
        <div v-for="toast in toasts" class="toast" :key="toast.id">
            <span v-if="isText(toast)">{{ toast.text }}</span>
            <component v-else v-bind:is="toast.template" :data="toast.data"></component>
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex";

    export default {
        computed: {
            ...mapState("toaster", {
                toasts: state => state.toasts
            }),
            anyToasts() { return this.toasts.length > 0; }
        },
        methods: {
            isText(toast) { return typeof toast.text === "string"; }
        }
    }
</script>

<style scoped>
    .toaster {
        position: fixed;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 20000;
        border: 0.125em solid var(--green-dark-2);
        border-top-width: 0;
        background: var(--green);
        border-radius: 0 0 1em 1em;
        text-align: center;
        overflow: hidden;
        max-width: 95%;
        width: fit-content;
    }
    .toast {
        padding: 1em 1em;
    }
    .toast + .toast {
        border-top: 1px solid var(--green-dark-2);
    }
</style>

<style>
    .toast-header {
        font-size: 1.25em;
        font-weight: bold;
        color: var(--darkblue);
    }
</style>