import { ethers } from "ethers";
import dogecoinAbi from "./dogecoinAbi"
import { contractAddress, infuraId, gasPrices } from "../env"
import mitt from "mitt"
import walletTypes from "./wallet-types"

import WalletConnectProvider from "@walletconnect/web3-provider";

let walletConnectProvider;
function getWalletConnectProvider() {
    if (walletConnectProvider) { return walletConnectProvider; }
    walletConnectProvider = new WalletConnectProvider({ infuraId });
    walletConnectProvider.on("chainChanged", handleChainChanged);
    walletConnectProvider.on("accountsChanged", handleAccountsChanged);
    walletConnectProvider.on("disconnect", handleDisconnect);
    walletConnectProvider.on("connect", handleConnect);
    walletConnectProvider.on("session_update", handleAccountsChanged);
    return walletConnectProvider;
}

const walletEvents = mitt();

const metamaskProvider = window.ethereum;
const metamaskInstalled = !!metamaskProvider;
let ethersProvider, signer, dogecoin;
let rebuildProvider = true;
let walletType = walletTypes.metamask;

function checkRebuildProvider() {
    if (rebuildProvider) {
        if (walletType == walletTypes.metamask) {
            ethersProvider = metamaskProvider ? new ethers.providers.Web3Provider(metamaskProvider) : null;
        }
        else if (walletType == walletTypes.walletConnect) {
            ethersProvider = new ethers.providers.Web3Provider(getWalletConnectProvider());
        }
        else {
            throw "Unknown wallet type";
        }
        signer = ethersProvider.getSigner();
        dogecoin = new ethers.Contract(contractAddress, dogecoinAbi, signer);
        rebuildProvider = false;
    }
}
function getEthersProvider() {
    checkRebuildProvider();
    return ethersProvider;
}
function getSigner() {
    checkRebuildProvider();
    return signer;
}
function getDogecoin() {
    checkRebuildProvider();
    return dogecoin;
}
function getWalletType() {
    return walletType;
}
function setWalletType(type) {
    walletType = type || walletTypes.metamask;
    rebuildProvider = true;
}

function handleChainChanged() {
    rebuildProvider = true;
    walletEvents.emit("chain");
}
function handleAccountsChanged() {
    rebuildProvider = true;
    walletEvents.emit("account");
}
function handleDisconnect() {
    console.log("Disconnected from wallet provider");
    rebuildProvider = true;
    if (walletConnectProvider) {        
        walletConnectProvider = null;
    }
    walletEvents.emit("disconnect");
}
function handleConnect() {
    console.log("Connected to wallet provider");
    rebuildProvider = true;
    walletEvents.emit("connect");
}

async function activateWallet() {
    if (walletType == walletTypes.metamask) {
        if (!metamaskProvider) { throw "Metamask is not installed"; }
        if (typeof metamaskProvider.isConnected == "function" && metamaskProvider.isConnected() === false) { throw "Metamask is disconnected. Reloading the page should fix it."; }
        await getEthersProvider().provider.request({ method: "eth_requestAccounts" });
    }
    else if (walletType == walletTypes.walletConnect) {
        await getWalletConnectProvider().enable();
    }
    else {
        throw "Unknown wallet type";
    }
    walletEvents.emit("activate");
}

function validateSignature(signature) {
    if (typeof signature !== "string" || !/^0x[0-9a-f]{130}$/i.test(signature)) {
        throw `${signature} is not a valid signature`;
    }
}

async function signMessage(address, message) {
    let signature;
    const request = { method: "personal_sign", params: [message, address] };
    if (walletType == walletTypes.metamask) {
        signature = await metamaskProvider.request(request);
    }
    else if (walletType == walletTypes.walletConnect) {
        signature = await getWalletConnectProvider().request(request);
    }
    validateSignature(signature);
    return signature;
}

async function getStatus() {
    if (walletType == walletTypes.walletConnect && !getWalletConnectProvider().isRunning()) {
        return { connected: false };
    }
    const signer = getSigner();
    const address = await signer.getAddress();
    const chainId = await signer.getChainId();
    if (!address || !chainId) { return { connected: false }; }
    return {
        address,
        chainId,
        connected: true
    };
}

async function addGasPrice(options) {
    options = options || {};
    const chainId = await getSigner().getChainId();
    const gasPrice = gasPrices[chainId];
    if (gasPrice) {
        options.gasPrice = gasPrice;
    }
    return options;
}

if (metamaskProvider) {
    metamaskProvider.autoRefreshOnNetworkChange = false;
    metamaskProvider.on("chainChanged", handleChainChanged);
    metamaskProvider.on("accountsChanged", handleAccountsChanged);
    metamaskProvider.on("disconnect", handleDisconnect);
    metamaskProvider.on("connect", handleConnect);
}

export {
    getDogecoin,
    getEthersProvider,
    getStatus,
    getWalletType,
    walletEvents,
    metamaskInstalled,
    setWalletType,
    activateWallet,
    signMessage,
    addGasPrice
}