<template>
    <footer class="footer">
        <TwoColumns>
            <template #one>
                <div><a href="mailto:support@doge.gay" class="White">support@doge.gay</a></div>
            </template>
            <template #two>
                <div><a :href="getContractExplorerUrl()" target="_blank" class="White">Etherscan</a></div>
                <div><a :href="getContractExplorerUrl('Binance')" target="_blank" class="White">Bscscan</a></div>                
                <div><a href="https://twitter.com/DogeGay" target="_blank" class="White">Twitter</a></div>
            </template>
        </TwoColumns>
    </footer>
</template>

<script>
    import { getContractExplorerUrl } from "../env"
    import TwoColumns from "./Layouts/TwoColumns"

    export default {
        components: {
            TwoColumns
        },
        methods: {
            getContractExplorerUrl
        }
    }
</script>

<style scoped>
    .footer {
        background: var(--darkblue);
        padding: 2em 1em;
        text-align: center;
    }
</style>