<template>
    <div class="value-panel" :class="{ inline }">
        <h3><slot>Balance</slot></h3>
        <div><span>{{ value }}</span> {{ units }}</div>
    </div>
</template>

<script>
    export default {
        props: {
            value: String,
            units: String,
            inline: Boolean
        }
    }
</script>

<style scoped>
    .value-panel {
        margin: 0 auto;
        background-color: var(--darkblue);
        color: var(--white);
        border-radius: 1em;
        padding: 0.5em;
        text-align: center;
    }
    .inline {
        display: inline-block;
        padding-left: 2em;
        padding-right: 2em;
    }
</style>