<template>
    <div class="bg fullsize" @click="closeModal"></div>
    <div class="modal" role="dialog" aria-modal="true">
        <div class="content">
            <slot></slot>
        </div>
        <CloseButton class="close" :onClick="closeModal" />
    </div>
</template>

<script>
    import { appActions } from "../../store/action-types"
    import { mapActions } from "vuex";
    import CloseButton from "../Buttons/CloseButton"

    let self;
    function handleEscape(e) {
        if (e && e.keyCode === 27) {
            self.closeModal();
            e.preventDefault();
            return false;
        }
    }

    export default {
        components: {
            CloseButton
        },
        methods: {
            ...mapActions("app", {
                closeModal: appActions.closeModal
            })
        },
        mounted() {
            self = this;
            window.addEventListener("keydown", handleEscape);
        },
        unmounted() {
            window.removeEventListener("keydown", handleEscape);
        }
    }
</script>

<style scoped>
    .fullsize {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .modal {
        position: fixed;

        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        max-height: 90%; 
        max-width: 90%;

        z-index: 10000;

        min-width: 30em;
        min-height: 30em;
    }
    .close {
        position: absolute;
        top: -0.5em;
        right: -0.5em;
        z-index: 10001;
    }
    .bg {
        z-index: 9999;
        position: fixed;
        background-color: #000;
        opacity: 0.6;
    }
    .content {
        position: relative;
        padding: 1em;
        overflow: auto;
        background-color: var(--orange);
        border-radius: 1em;
        box-shadow: 0.125em 0.5em 0.5em rgba(0,0,0,0.5);
    }

    @media (max-width: 40em) {
        .modal {
            min-width: 75%;
        }
    }
    @media (max-height: 40em) {
        .modal {
            min-height: 75%;
        }
    }
</style>