<template>
    <Modal>
        <div>
            All deposit addresses and funds are stored with this extended public key address
        </div>
        <div class="Address xpub">{{ xPub }}</div>
        <div>
            {{ maxTweak }} addresses have been generated so far
        </div>
    </Modal>
</template>

<script>
    import { mapState } from "vuex"
    import Modal from "../Layouts/Modal"

    export default {
        components: {
            Modal
        },
        computed: {
            ...mapState("app", {
                xPub: state => state.globalStats ? state.globalStats.xPub : null,
                maxTweak: state => state.globalStats ? state.globalStats.maxTweak : 0
            })
        }
    }
</script>

<style scoped>
    .xpub {
        padding: 1em;
        border: 1px solid var(--orange-dark-1);
        border-radius: 1em;
        margin: 1em 0;
    }
</style>