<template>
    <section class="stats">
        <h2>Stats</h2>
        <button @click="openXPub">Display XPub</button>

        <ThreeColumns>
            <template #one>
                <div class="ControlPanel">
                    <h2>Dogecoin Blockchain</h2>
                    <div class="stat">
                        <table v-if="hasDogecoinStats" class="stat-table">
                            <tr>
                                <td>Deposits</td>
                                <td>{{ dogecoinStats.depositCount }}</td>
                            </tr>
                            <tr>
                                <td>Total Deposited</td>
                                <td>{{ friendlyAmount(dogecoinStats.totalDepositAmount, 0) }} DOGE</td>
                            </tr>
                            <tr>
                                <td>Last Block</td>
                                <td>{{ dogecoinStats.blockHeight }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </template>
            <template #two>
                <div class="ControlPanel">
                    <h2>Ethereum Blockchain</h2>
                    <div class="stat">
                        <table v-if="hasEthereumStats" class="stat-table">
                            <tr>
                                <td>Deposits</td>
                                <td>{{ ethereumStats.depositCount }}</td>
                            </tr>
                            <tr>
                                <td>Total Deposited</td>
                                <td>{{ friendlyAmount(ethereumStats.totalDepositAmount, 0) }} DOGE</td>
                            </tr>
                            <tr>
                                <td>Last Block</td>
                                <td>{{ ethereumStats.blockHeight }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </template>
            <template #three>
                <div class="ControlPanel">
                    <h2>Binance Blockchain</h2>
                    <div class="stat">
                        <table v-if="hasBinanceStats" class="stat-table">
                            <tr>
                                <td>Deposits</td>
                                <td>{{ binanceStats.depositCount }}</td>
                            </tr>
                            <tr>
                                <td>Total Deposited</td>
                                <td>{{ friendlyAmount(binanceStats.totalDepositAmount, 0) }} DOGE</td>
                            </tr>
                            <tr>
                                <td>Last Block</td>
                                <td>{{ binanceStats.blockHeight }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </template>
        </ThreeColumns>

        <button @click="openGlobalUnminted">Global Unminted</button>
    </section>
</template>

<script>
    import { mapActions, mapState } from "vuex"
    import { appActions } from "../store/action-types"
    import ThreeColumns from "./Layouts/ThreeColumns"
    import { friendlyAmount } from "../js/helpers"

    export default {
        components: {
            ThreeColumns
        },
        computed: {
            ...mapState("app", {
                globalStats: state => state.globalStats,
                networkStats: state => state.globalStats ? state.globalStats.networkStats : {}
            }),
            dogecoinStats() { return this.networkStats["dogecoin"]; },
            ethereumStats() { return this.networkStats["ethereum"]; },
            binanceStats() { return this.networkStats["binance"]; },
            hasDogecoinStats() { return !!this.dogecoinStats; },
            hasEthereumStats() { return !!this.ethereumStats; },
            hasBinanceStats() { return !!this.binanceStats; }
        },
        methods: {
            friendlyAmount,
            ...mapActions("app", {
                openXPub: appActions.openXPub,
                openGlobalUnminted: appActions.openGlobalUnminted
            })
        }
    }
</script>

<style scoped>
    .stats {
        background-color: var(--lightblue);
        overflow: hidden;
        text-align: center;
        padding: 1em 0;
    }
    .stat {
        padding: 1em;
    }
    .stat-table {
        width: 100%;
        text-align: right;        
    }
    .stat-table td:last-child {
        text-align: left;
        padding-left: 1em;
    }
</style>