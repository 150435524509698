import { toasterActions } from "../action-types"

const defaultDuration = 5000;
let lastId = 0;

const state = () => ({
    toasts: [
        /*
        {
            id: 1,
            text: "",
            template: object,
            duration: 5000,
            until: 0
        }
         */
    ]
})

const mutations = {
    show(state, { id, text, template, duration, data }) {  
        if (!text && !template) { return; }
        state.toasts.push({
            id,
            text,
            template,
            duration,
            data,
            until: Date.now() + duration
        });
    },
    remove(state, { id }) {        
        for (let x = state.toasts.length - 1; x >= 0; --x) {
            if (state.toasts[x].id == id) {
                state.toasts.splice(x, 1);
            }
        }
    }
}

const actions = {
    [toasterActions.show]({ commit }, { text, template, duration, data }) {
        if (!duration || duration <= 0) { duration = defaultDuration; }
        const id = ++lastId;
        commit("show", { id, text, template, duration, data });
        return new Promise(done => {
            setTimeout(() => {
                commit("remove", { id });
                done();
            }, duration);
        });
    },
    [toasterActions.remove]({ commit }, { id }) {
        commit("remove", { id });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}