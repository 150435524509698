import { appActions, connectionActions, sessionActions, contractActions, toasterActions } from "../action-types"
import modals from "../../modals"

let lastGlobalStats = 0;
let modalPromiseResolve;

async function showModal({ commit, modal }) {
    if (modalPromiseResolve) { modalPromiseResolve(); }
    modalPromiseResolve = null;
    return new Promise(resolve => {
        if (modal) {
            modalPromiseResolve = resolve;
        }
        commit("showModal", { modal });
    });
}

const state = () => ({
    globalStats: {
        xPub: "",
        maxTweak: 0,
        unclaimedBalances: 0,
        networkStats: {
            //dogecoin: {
            //    depositCount: 0,
            //    totalDepositAmount: "0",
            //    blockHeight: 0,
            //    blockDate: "",
            //    totalSupply: "0",
            //    unmintedSendInstructions: [
            //        {
            //            instructionId: 0,
            //            amountToSend: "0",
            //            fee: "0",
            //            destinationAddress: "",
            //            mintSignature: "",
            //            date: ""
            //        }
            //    ]
            //}
        }
    },
    sessionData: {
        controllerAddress: "",
        controllerDate: "",
        balance: 0,
        dogecoinAddresses: [
            //{
            //    controllerAddress:"",
            //    address:"",
            //    xPub: "",
            //    tweak: 0
            //}
        ],
        deposits: [
            //{
            //    controllerAddress: "",
            //    address: "",
            //    date: "",
            //    amount: "0",
            //    creditedDate: "",
            //    networkName: "",
            //    transactionHash: ""
            //}
        ],
        sendInstructions: [
            //{
            //    controllerAddress: "",
            //    instructionId: 0,
            //    date: "",
            //    destinationAddress: "",
            //    amountToSend: "0",
            //    fee: "0",
            //    controllerMessage: "",
            //    controllerSignature: "",
            //    networkName: "",
            //    abortReason: "",
            //    transactionHash: "",
            //    mintSignature: "",
            //    fulfillmentDate: ""
            //}
        ]
    },
    hasSessionData: false,

    showModal: "",
    signInAuto: false,
    sendFromBridgeTarget: ""
})

const mutations = {
    setControllerAddress(state, { controllerAddress }) {
        state.controllerAddress = controllerAddress;
    },
    setGlobalStats(state, globalStats) {
        state.globalStats = globalStats;
        lastGlobalStats = Date.now();
    },
    setSessionData(state, sessionData) {
        if (sessionData.controllerAddress !== state.sessionData.controllerAddress) {
            state.sessionData = {};
        }
        state.sessionData = Object.assign({}, state.sessionData, sessionData);
        state.hasSessionData = !!sessionData.controllerAddress;
    },
    setBalance(state, { controllerAddress, balance }) {
        if (state.sessionData.controllerAddress !== controllerAddress) { return; }
        state.sessionData.balance = balance;
    },
    addDogecoinAddress(state, dogecoinAddress) {
        if (state.sessionData.controllerAddress !== dogecoinAddress.controllerAddress) { return; }
        if (state.sessionData.dogecoinAddresses.findIndex(x => x.address == dogecoinAddress.address) !== -1) { return; }
        state.sessionData.dogecoinAddresses.unshift(dogecoinAddress);
    },
    addDeposit(state, deposit) {
        if (state.sessionData.controllerAddress !== deposit.controllerAddress) { return; }
        const index = state.sessionData.deposits.findIndex(x => x.depositId == deposit.depositId);
        if (index === -1) {
            state.sessionData.deposits.unshift(deposit);
        }
        else {
            state.sessionData.deposits[index] = deposit;
        }
    },
    addSendInstruction(state, sendInstruction) {
        if (state.sessionData.controllerAddress !== sendInstruction.controllerAddress) { return; }
        const index = state.sessionData.sendInstructions.findIndex(x => x.instructionId == sendInstruction.instructionId);
        if (index === -1) {
            state.sessionData.sendInstructions.unshift(sendInstruction);
        }
        else {
            state.sessionData.sendInstructions[index] = sendInstruction;
        }
    },
    showModal(state, { modal }) {
        state.showModal = modal;
    },
    setSendFromBridgeTarget(state, target) {
        state.sendFromBridgeTarget = target;
    },
    setAutoSignIn(state, auto) {
        state.signInAuto = !!auto;
    }
}

const actions = {
    [appActions.handleGlobalStats]({ commit }, globalStats) {
        commit("setGlobalStats", globalStats);
    },
    [appActions.handleSessionData]({ commit }, { sessionData }) {
        commit("setSessionData", sessionData);
    },
    [appActions.handleBalance]({ commit }, data) {
        commit("setBalance", data);
    },
    [appActions.handleDogecoinAddress]({ commit }, { dogecoinAddress }) {
        commit("addDogecoinAddress", dogecoinAddress);
    },
    [appActions.handleDeposit]({ commit, state, dispatch }, { deposit }) {
        const oldDeposit = state.sessionData.deposits.find(x => x.depositId == deposit.depositId);
        if (!oldDeposit || oldDeposit.transactionHash != deposit.transactionHash) {
            dispatch(`toaster/${toasterActions.show}`, { template: "deposit-toast", data: deposit }, { root: true });
        }
        commit("addDeposit", deposit);
    },
    [appActions.handleSendInstruction]({ commit, state, dispatch }, { sendInstruction }) {
        const oldSend = state.sessionData.sendInstructions.find(x => x.instructionId == sendInstruction.instructionId);
        if (!oldSend || oldSend.fulfillmentDate != sendInstruction.fulfillmentDate || oldSend.transactionHash != sendInstruction.transactionHash) {
            dispatch(`toaster/${toasterActions.show}`, { template: "send-instruction-toast", data: sendInstruction }, { root: true });
        }
        commit("addSendInstruction", sendInstruction);
    },
    [appActions.handleVersion](_, version) {
        if (window.expectedServerVersion) {
            console.log(`Server version is ${version}, expected ${window.expectedServerVersion}`);
        }
    },

    async [appActions.handleConnectionConnected]({ dispatch }) {
        const tasks = [];
        if (Date.now() - lastGlobalStats > 120) {
            tasks.push(dispatch(`connection/${connectionActions.getGlobalStats}`, null, { root: true }));
        }
        tasks.push(dispatch(`app/${appActions.tryStartCachedSession}`, null, { root: true }));
        await Promise.all(tasks);
    },
    async [appActions.handleConnectionDisconnected]({ dispatch }) {
        await dispatch(`session/${sessionActions.endSession}`, null, { root: true });
    },

    async [appActions.handleWalletConnected]({ dispatch }) {
        const tasks = [];
        tasks.push(dispatch(`app/${appActions.tryStartCachedSession}`, null, { root: true }));
        tasks.push(dispatch(`contract/${contractActions.watch}`, null, { root: true }));
        await Promise.all(tasks);
    },

    async [appActions.tryStartCachedSession]({ commit, dispatch, rootState }) {
        if (rootState.session.controllerAddress != rootState.wallet.address) {
            await dispatch(`session/${sessionActions.endSession}`, null, { root: true });
        }
        try {
            const controllerAddress = rootState.wallet.address;
            if (controllerAddress) {
                await dispatch(`session/${sessionActions.startCachedSession}`, { controllerAddress }, { root: true });
            }
        }
        catch (e) {
            commit("setSessionData", {});
        }
    },

    async [appActions.startSession]({ dispatch, rootState }) {
        const controllerAddress = rootState.wallet.address;
        await dispatch(`session/${sessionActions.startSession}`, { controllerAddress }, { root: true });
    },

    async [appActions.sendToBridge]({ dispatch, rootState }, amount) {
        const controllerAddress = rootState.wallet.address;
        await dispatch(`session/${sessionActions.sendToBridge}`, { controllerAddress, amount }, { root: true });
    },
    async [appActions.sendDoge]({ dispatch, rootState }, { amount, destinationAddress, network }) {
        const controllerAddress = rootState.wallet.address;
        await dispatch(`session/${sessionActions.sendDoge}`, { controllerAddress, amount, destinationAddress, network }, { root: true });
    },
    async [appActions.mint]({ dispatch }, sendInstruction) {
        await dispatch(`session/${sessionActions.mint}`, { sendInstruction }, { root: true });
        await dispatch(`contract/${contractActions.watch}`, null, { root: true });
    },
    async [appActions.mintAll]({ dispatch, rootState }, network) {        
        const sendInstructions = rootState.app.globalStats.networkStats[network.toLowerCase()].unmintedSendInstructions;
        await dispatch(`session/${sessionActions.mintAll}`, { sendInstructions }, { root: true });
        await dispatch(`contract/${contractActions.watch}`, null, { root: true });
    },

    [appActions.assignDepositAddress]({ dispatch }) { return dispatch(`connection/${connectionActions.assignDogecoinAddress}`, null, { root: true }) },

    [appActions.closeModal]({ commit }) { showModal({ commit, modal: "" }); },
    [appActions.openConnectWallet]({ commit }) { return showModal({ commit, modal: modals.connectWallet }); },
    async [appActions.openSignIn]({ commit, dispatch, rootState }, options) {
        const { auto } = options || {};
        if (!rootState.wallet.connected) {
            await dispatch(`app/${appActions.openConnectWallet}`, null, { root: true });
        }
        if (rootState.wallet.connected) {
            commit("setAutoSignIn", auto);
            await showModal({ commit, modal: modals.signIn });
        }
    },
    async [appActions.openSendFromDogecoin]({ commit, dispatch, rootState }) {
        if (!rootState.session.connected) {
            await dispatch(`app/${appActions.openSignIn}`, null, { root: true });
        }
        if (rootState.session.connected) {
            await showModal({ commit, modal: modals.sendFromDogecoin });
        }
    },
    async [appActions.openSendFromEthereum]({ commit, dispatch, rootState }) {
        if (!rootState.session.connected) {
            await dispatch(`app/${appActions.openSignIn}`, null, { root: true });
        }
        if (rootState.session.connected) {
            await showModal({ commit, modal: modals.sendFromEthereum });
        }
    },
    async [appActions.openSendToDogecoin]({ commit, dispatch, rootState }) {
        if (!rootState.session.connected) {
            await dispatch(`app/${appActions.openSignIn}`, null, { root: true });
        }
        if (rootState.session.connected) {
            commit("setSendFromBridgeTarget", "Dogecoin");
            await showModal({ commit, modal: modals.sendFromBridge });
        }
    },
    async [appActions.openSendToEthereum]({ commit, dispatch, rootState }) {
        if (!rootState.session.connected) {
            await dispatch(`app/${appActions.openSignIn}`, null, { root: true });
        }
        if (rootState.session.connected) {
            commit("setSendFromBridgeTarget", "Ethereum");
            await showModal({ commit, modal: modals.sendFromBridge });
        }
    },
    async [appActions.openSendToBinance]({ commit, dispatch, rootState }) {
        if (!rootState.session.connected) {
            await dispatch(`app/${appActions.openSignIn}`, null, { root: true });
        }
        if (rootState.session.connected) {
            commit("setSendFromBridgeTarget", "Binance");
            await showModal({ commit, modal: modals.sendFromBridge });
        }
    },
    [appActions.openXPub]({ commit }) { return showModal({ commit, modal: modals.xPub }); },
    [appActions.openGlobalUnminted]({ commit }) { return showModal({ commit, modal: modals.globalUnminted }); },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}