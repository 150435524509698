import { utils } from "ethers";

function inputNumbersOnly(e) {
    const val = e.target.value;
    if (/^[0-9.]*$/.test(val)) { return; }
    e.target.value = val.replace(/[^0-9.]/g, "");
}
function inputAddressOnly(e) {
    const val = e.target.value;
    if (/^[0-9A-HJ-NP-Za-km-z]*$/.test(val)) { return; }
    e.target.value = val.replace(/[^0-9A-HJ-NP-Za-km-z]/g, "");
}
function removeTrailingDecimals(str) {
    const parts = str.split('.');
    if (parts.length != 2) { return str; }
    parts[1] = parts[1].replace(/0+$/, "");
    if (!parts[1].length) { return parts[0]; }
    return `${parts[0]}.${parts[1]}`;
}
function dogeNumberize(num) {
    if (typeof num === "string" && /^[0-9]*\.?[0-9]*$/.test(num)) { num = parseFloat(num); }
    if (isNaN(num)) { return null; }
    return removeTrailingDecimals(num.toFixed(8));
}
function choppedAddress(a) {
    if (typeof a !== "string" || a.length < 12) { return a; }
    return a.substr(0, 8) + "…" + a.substr(a.length - 4);
}
function asDogeNumber(amount) {
    return parseFloat(dogeNumberize(amount));
}
function isEthereumAddress(address) {
    return /^0x[0-9a-fA-F]{40}$/.test(address);
}
function isDogecoinAddress(address) {
    return /^[1-9A-HJ-NP-Za-km-z]{25,35}$/.test(address);
}
function friendlyDate(isoDate) {
    return new Date(isoDate).toLocaleDateString(undefined, { year: "numeric", month: "short", day: "numeric" });
}
function commify(str) {
    str = utils.commify(str);
    if (/\.0$/.test(str)) { return str.substr(0, str.length - 2); }
    return str;
}
function truncateDecimals(str, maxDecimals) {
    if (typeof maxDecimals === "number" && str.length > (maxDecimals + 1)) {        
        const i = str.indexOf('.');
        if (i !== -1 && str.length - i > maxDecimals) {
            str = str.substr(0, i + maxDecimals + 1);
        }
    }
    return removeTrailingDecimals(str);
}
function friendlyAmount(str, maxDecimals) {
    if (typeof str === "number") { str = str.toFixed(18); }
    if (!str || typeof str !== "string") { return ""; }    
    return truncateDecimals(commify(str), maxDecimals);
}
function explorerUrl(transactionHash, network) {
    if (network == "Dogecoin") { return `https://dogechain.info/tx/${transactionHash}`; }
    if (network == "Ethereum") { return `https://etherscan.io/tx/0x${transactionHash}`; }
    if (network == "Binance") { return `https://bscscan.com/tx/0x${transactionHash}`; }
    return "";
}

export {
    inputNumbersOnly,
    inputAddressOnly,
    dogeNumberize,
    choppedAddress,
    asDogeNumber,
    isEthereumAddress,
    isDogecoinAddress,
    friendlyDate,
    friendlyAmount,
    explorerUrl,
    removeTrailingDecimals
}