const memoryStorage = {};
const storage = {
    get: key => {
        if (typeof memoryStorage[key] !== "undefined") {
            return memoryStorage[key] || null;
        }
        try {
            return localStorage.getItem(key);
        }
        catch (e) {
            return null;
        }
    },
    set: (key, val) => {
        memoryStorage[key] = val;
        try {
            localStorage.setItem(key, val);
        }
        catch (e) {/**/ }
    },
    remove: key => {
        delete memoryStorage[key];
        try {
            localStorage.removeItem(key);
        }
        catch (e) {/**/ }
    }
};

export {
    storage
}