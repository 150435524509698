<template>
    <Modal>
        <div class="send-from-dogecoin">
            <div v-if="!haveAddress">Generating an address...</div>
            <div v-else>
                <div ref="DogeDepositQR" class="QR" :title="address"></div>
                <div class="Address deposit-address">{{ address }}</div>
            </div>
            <div>
                Credited within 1-3 network confirmations and controlled by your {{ network }} address
                <span class="Address" :title="controllerAddress">{{ choppedAddress(controllerAddress) }}</span>
            </div>
            <div>
                <button @click="generate" :disabled="generating">Generate New Address</button>
            </div>
            <div v-if="error" class="Error">{{ error }}</div>
        </div>
    </Modal>
</template>
<script>
    import Modal from "../Layouts/Modal"
    import { appActions } from "../../store/action-types"
    import { mapState } from "vuex";
    import { choppedAddress } from "../../js/helpers"
    import { ensureConnected, friendlyError } from "../../store/errorHelper"

    const QRCode = require("qrcode-svg")

    let self;
    async function handleCurrentState() {
        if (!self.walletConnected || !self.sessionConnected) {
            self.$store.dispatch(`app/${appActions.closeModal}`, null, { root: true });
        }
    }
    export default {
        components: {
            Modal
        },
        data() {
            return {
                generating: false,
                error: ""
            };
        },
        computed: {
            ...mapState("session", {
                sessionConnected: state => state.connected,
            }),
            ...mapState("wallet", {
                walletConnected: state => state.connected,
                controllerAddress: state => state.address,
                rawNetwork: state => state.network
            }),
            ...mapState("app", {
                address: state => state.hasSessionData && state.sessionData.dogecoinAddresses.length ? state.sessionData.dogecoinAddresses[0].address : null,
                haveAddress: state => state.hasSessionData && state.sessionData.dogecoinAddresses.length > 0
            }),
            network() { return this.rawNetwork || "Ethereum"; }
        },
        watch: {
            walletConnected: handleCurrentState,
            sessionConnected: handleCurrentState,
            address: function () {
                this.updateQR();
            }
        },
        methods: {
            choppedAddress,
            generate: async function () {
                this.error = await ensureConnected(this);
                if (this.error) { return; }
                try {
                    this.generating = true;
                    await this.$store.dispatch(`app/${appActions.assignDepositAddress}`, null, { root: true });
                }
                catch (e) {
                    this.error = friendlyError(e, "Failed to generate a new address - try again soon");
                }
                this.generating = false;
            },
            updateQR() {
                if (!this.haveAddress) { return; }
                const address = this.address;
                const qr = this.$refs.DogeDepositQR;
                if (!qr) {
                    this.$nextTick(this.updateQR);
                    return;
                }
                if (qr.getAttribute("data-address") == address) { return; }
                qr.textContent = "";
                qr.setAttribute("data-address", "");
                qr.innerHTML = new QRCode({
                    content: address,
                    container: "svg-viewbox",
                    join: true,
                    background: "transparent",
                    padding: 0
                }).svg();
                qr.setAttribute("data-address", address);
            }
        },
        mounted() {
            self = this;
            handleCurrentState();
            if (!self.haveAddress) {
                self.generate();
            }
            else {
                self.updateQR();
            }
        }
    }
</script>
<style scoped>
    .send-from-dogecoin {
        text-align: center;
        max-width: 40em;
    }

    .deposit-address {
        padding-bottom: 1em;
    }

    .QR {
        margin: 1em 0;
    }
</style>
