import { sessionActions, connectionActions } from "../action-types"
import { signMessage, getDogecoin, addGasPrice } from "../../js/wallet"
import { storage } from "../../js/storage"
import { dogeNumberize } from "../../js/helpers"
import { utils } from "ethers"

function getStorageKey(address) {
    return `sessionKey_${address}`;
}
function checkController(state, controllerAddress) {
    if (state.controllerAddress !== controllerAddress) {
        throw `Session controller = "${state.controllerAddress}" but action requested on "${controllerAddress}"`;
    }
}
function validateAmount(amount) {
    if (!amount || amount <= 0) {
        throw `${amount} is not a valid amount`;
    }
}

const state = () => ({
    connected: false,
    connecting: false,
    controllerAddress: ""
})

const mutations = {
    setDisconnected(state) {
        state.connected = state.connecting = false;
        state.controllerAddress = "";
    },
    setConnecting(state) {
        state.connected = false;
        state.connecting = true;
        state.controllerAddress = "";
    },
    setConnected(state, { controllerAddress }) {
        state.connected = true;
        state.connecting = false;
        state.controllerAddress = controllerAddress;
    }
}

const actions = {
    async [sessionActions.startSession]({ dispatch, commit, state }, { controllerAddress }) {
        if (state.controllerAddress == controllerAddress) { return; }
        const message = `Login to doge.gay\nDate: ${new Date().toISOString()}`;
        commit("setConnecting");
        try {
            const signature = await signMessage(controllerAddress, message);
            const sessionBytes = new Uint8Array(16);
            crypto.getRandomValues(sessionBytes);
            const sessionKey = sessionBytes.reduce((prev, x) => `${prev}${("0" + x.toString(16)).slice(-2)}`, "");
            await dispatch(`connection/${connectionActions.startSession}`, { controllerAddress, sessionKey, message, signature }, { root: true });
            storage.set(getStorageKey(controllerAddress), sessionKey);
            commit("setConnected", { controllerAddress });
        }
        catch (e) {
            if (state.controllerAddress == controllerAddress) { return; }
            commit("setDisconnected");
            throw e;
        }
    },
    async [sessionActions.endSession]({ commit, dispatch }) {
        try {
            commit("setDisconnected");
            await dispatch(`connection/${connectionActions.endSession}`, null, { root: true });
        }
        catch (e) {/**/ }
    },
    async [sessionActions.startCachedSession]({ commit, dispatch, state }, { controllerAddress }) {
        if (state.controllerAddress == controllerAddress) { return; }
        const sessionKey = storage.get(getStorageKey(controllerAddress));
        if (!sessionKey) { throw "No cached session key"; }
        commit("setConnecting");
        try {
            await dispatch(`connection/${connectionActions.resumeSession}`, { sessionKey }, { root: true });
            commit("setConnected", { controllerAddress });
        }
        catch (e) {
            if (state.controllerAddress == controllerAddress) { return; }
            commit("setDisconnected");
        }
    },
    async [sessionActions.sendDoge]({ state, dispatch }, { controllerAddress, amount, destinationAddress, network }) {
        checkController(state, controllerAddress);
        amount = dogeNumberize(amount);
        validateAmount(amount);
        const fee = network == "Dogecoin" ? "1" : "0";
        const message = `Send ${amount} to ${destinationAddress} on ${network} with fee ${fee}\n[end of instructions]\n${new Date().toISOString()}`;
        const signature = await signMessage(controllerAddress, message);
        await dispatch(`connection/${connectionActions.sendDoge}`, { amount, destinationAddress, network, fee, message, signature }, { root: true });
    },
    async [sessionActions.sendToBridge]({ state }, { controllerAddress, amount }) {
        checkController(state, controllerAddress);
        amount = dogeNumberize(amount);
        validateAmount(amount);
        const dogecoin = getDogecoin();
        const tx = await dogecoin.transfer(dogecoin.address, utils.parseUnits(amount, 8), await addGasPrice());
        await tx.wait();
    },
    async [sessionActions.mint](_, { sendInstruction }) {
        const r = "0x" + sendInstruction.mintSignature.substr(0, 64);
        const s = "0x" + sendInstruction.mintSignature.substr(64, 64);
        const v = parseInt(sendInstruction.mintSignature.substr(128, 2), 16);
        const amountToSend = utils.parseUnits(sendInstruction.amountToSend, 8);
        const tx = await getDogecoin().joinParty(sendInstruction.destinationAddress, amountToSend, sendInstruction.instructionId, v, r, s, await addGasPrice());
        await tx.wait();
    },
    async [sessionActions.mintAll](_, { sendInstructions }) {
        if (!sendInstructions || !sendInstructions.length) { return; }
        const all = sendInstructions.map(x => ({
            r: "0x" + x.mintSignature.substr(0, 64),
            s: "0x" + x.mintSignature.substr(64, 64),
            v: parseInt(x.mintSignature.substr(128, 2), 16),
            amount: utils.parseUnits(x.amountToSend, 8),
            instructionId: x.instructionId,
            to: x.destinationAddress
        }));
        const tx = await getDogecoin().multiJoinParty(all, await addGasPrice());
        await tx.wait();
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}