<template>
    <section>
        <div class="intro">
            <div class="intro-doge">
                <FullDoge></FullDoge>
            </div>
            <div class="intro-bridge">
                <img src="../assets/bridge.png" alt="Bridge" class="intro-bridge-img" />
            </div>
            <div class="intro-combodoge">
                <DogeCZ v-if="isBinance"></DogeCZ>
                <DogeVitalik v-else></DogeVitalik>
            </div>
        </div>
    </section>
</template>

<script>
    import { isBinance } from "../env"
    import FullDoge from "./Doges/FullDoge"
    import DogeVitalik from "./Doges/DogeVitalik"
    import DogeCZ from "./Doges/DogeCZ"
    import { mapState } from "vuex"

    export default {
        computed: {
            ...mapState("wallet", {
                chainId: state => state.chainId
            }),
            isBinance() { return isBinance(this.chainId); }
        },
        components: {
            FullDoge,
            DogeVitalik,
            DogeCZ
        }
    }
</script>

<style scoped>
    .intro {
        position: relative;
        width: 100%;
        max-width: 60em;
        margin: 0 auto;
        max-height: 30em;
        height: 75vw;
    }
    .intro-doge {
        position: absolute;
        max-width: 15em;
        left: 0;
        z-index: 2;
        top: 50%;
        transform: translateY(-50%);
        width: 37.5vw;
    }
    .intro-bridge {
        position: absolute;
        z-index: 1;
        top: 75%;
        left: 22%;
        width: 55%;
        transform: translateY(-50%);
    }
    .intro-bridge-img {
        width: 100%;
    }
    .intro-combodoge {
        position: absolute;
        max-width: 20em;
        right: 0;
        z-index: 2;
        top: 50%;
        transform: translateY(-50%);
        width: 50vw;
    }
    @media (max-width: 50em) {
        .intro-bridge {
            left: 27%;
            width: 45%;
        }
    }
    @media (max-width: 40em) {
        .intro-bridge {
            left: 27%;
            width: 45%;
        }
    }
</style>