<template>
    <div class="doge-builder">
        <img :src="url" :alt="alt" class="doge-img" />
        <span v-for="txt in text" class="doge-text" :style="txt.style" :key="txt.words">{{ txt.words }}</span>
    </div>
</template>

<script>
    export default {
        props: {
            url: String,
            alt: String,
            text: Array
        }
    }
</script>

<style scoped>
    .doge-builder {
        position: relative;
        font-weight: bold;
        user-select: none;
    }

    .doge-img {
        width: 100%;
    }

    .doge-text {
        position: absolute;
        display: inline-block;
        text-align: center;
        font-size: 0.875rem;
    }
</style>