<template>
    <section v-if="walletConnected" class="wallet-banner">
        <div>
            Controller: <span>{{ controllerAddress }}</span>
        </div>
        <span>{{ ethBalance }} {{ nativeSymbol }}</span>
        <span>{{ dogeBalance }} DOGE on {{ network }}</span>
    </section>
</template>

<script>
    import { mapState } from "vuex";
    import { friendlyAmount } from "../js/helpers"

    const decimalsToShow = 3;

    export default {
        computed: {
            ...mapState("wallet", {
                walletConnected: state => state.connected,
                controllerAddress: state => state.address,
                nativeSymbol: state => state.nativeSymbol,
                network: state => state.network
            }),
            ...mapState("contract", {
                dogeBalance: state => friendlyAmount(state.dogeBalance, decimalsToShow),
                ethBalance: state => friendlyAmount(state.ethBalance, decimalsToShow)
            })
        }
    }
</script>

<style scoped>
    .wallet-banner {
        background-color: var(--darkblue-dark-1);
        font-size: 0.75em;
        display: grid;
        justify-items: center;
    }
</style>