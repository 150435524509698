<template>
    <DogeBuilder :url="url" alt="Doge" :text="text"></DogeBuilder>
</template>

<script>
    import DogeBuilder from "./DogeBuilder"
    const Image = require("../../assets/czdoge.png")

    export default {
        data() {
            return {
                url: Image,
                text: [
                    {
                        words: "i dream of cz",
                        style: {
                            "max-width": "6em",
                            right: "3%",
                            bottom: "33%",
                            color: "var(--yellow)"
                        }
                    },
                    {
                        words: "pamp pamp",
                        style: {
                            left: "0%",
                            top: "20%",
                            color: "var(--red)"
                        }
                    },
                    {
                        words: "pamp pamp",
                        style: {
                            left: "55%",
                            top: "25%",
                            color: "var(--red)"
                        }
                    },
                    {
                        words: "grant wish",
                        style: {
                            left: "35%",
                            top: "45%",
                            color: "var(--green)"
                        }
                    }
                ]
            }
        },
        components: {
            DogeBuilder
        }
    }
</script>