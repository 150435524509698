<template>
    <Modal>
        <div class="sign-in">
            <h2>Sign In</h2>
            <div class="sign-in-controls">
                <button @click="signIn" :disabled="!!waiting">Sign In</button>
            </div>
            <div v-if="waiting">{{ waiting }}</div>
            <div v-if="error" class="Error">{{ error }}</div>
        </div>
    </Modal>
</template>

<script>
    import Modal from "../Layouts/Modal"
    import { appActions } from "../../store/action-types"
    import { mapActions, mapState } from "vuex";
    import { friendlyError } from "../../store/errorHelper";

    let self;

    function shouldRedirect() { return self.sessionConnected || !self.walletConnected; }
    async function handleCurrentState() {
        if (shouldRedirect()) {
            await self.$store.dispatch(`app/${appActions.closeModal}`, null, { root: true });
        }
    }

    export default {
        data() {
            return {
                waiting: "",
                error: ""
            }
        },
        components: {
            Modal
        },
        computed: {
            ...mapState("session", {
                sessionConnected: state => state.connected
            }),
            ...mapState("wallet", {
                walletConnected: state => state.connected
            }),
            ...mapState("app", {
                signInAuto: state => state.signInAuto
            })
        },
        watch: {
            sessionConnected: handleCurrentState,
            walletConnected: handleCurrentState
        },
        methods: {
            ...mapActions("app", {
                startSession: appActions.startSession
            }),
            async signIn() {
                this.error = "";
                this.waiting = "Waiting for signature confirmation from your wallet...";
                try {
                    await this.startSession();
                }
                catch (e) {
                    this.error = friendlyError(e, "Sign in failed");
                }
                this.waiting = "";
            }
        },
        mounted() {
            self = this;
            handleCurrentState();
            if (!shouldRedirect() && this.signInAuto) {
                this.signIn();
            }
        }
    }
</script>

<style scoped>
    .sign-in {
        text-align: center;
    }
    .sign-in-controls {
        padding-top: 1em;
    }
</style>