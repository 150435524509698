import { networkByChainId, networkChainIds } from "../env";

async function ensureConnected(self, { network } = {}) {
    const state = self.$store.state;
    if (!state.connection.connected) {
        return "Disconnected from server";
    }
    if (!state.wallet.connected) {
        return "Wallet is not connected";
    }
    if (!state.session.connected) {
        return "Please sign in first";
    }
    if (network && state.wallet.chainId != networkChainIds[network]) {
        const currentNetwork = networkByChainId[state.wallet.chainId];
        if (currentNetwork) {
            return `Wallet is connected to ${currentNetwork}, but should be connected to ${network} instead`;
        }
        return `Wallet should be connected to ${network}, but is connected to a network with chain id ${state.wallet.chainId} instead`;
    }
    return "";
}

function friendlyError(e, fallback) {
    if (!e) { return "Error"; }
    if (typeof e === "string") { return e; }    
    if (typeof e === "object" && e.message) { return e.message; }
    console.error(e);
    if (fallback) { return fallback; }
    return "Error";
}

export {
    ensureConnected,
    friendlyError
}