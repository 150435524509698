<template>

    <div class="ControlPanel">
        <h2>Deposits</h2>
        <div v-if="!anyDeposits">
            No deposits yet!
        </div>
        <div class="deposits">
            <table class="deposit-list">
                <template v-for="(dep, i) in deposits" :key="dep.depositId">
                    <tr v-if="!dep.creditedDate" class="conf" :class="{ alt: ~i&1 }">
                        <td colspan="3" class="pad-top">
                            waiting for network confirmations
                        </td>
                    </tr>
                    <tr :class="{ alt: ~i&1 }">
                        <td class="pad-top">{{ friendlyAmount(dep.amount) }} DOGE</td>
                        <td class="date pad-top">{{ friendlyDate(dep) }}</td>
                    </tr>
                    <tr :class="{ alt: ~i&1 }">
                        <td colspan="2" class="Address">{{ dep.address || dep.controllerAddress }}</td>
                    </tr>
                    <tr :class="{ alt: ~i&1 }">
                        <td class="network pad-bottom" colspan="2">{{ dep.networkName }}</td>
                    </tr>
                </template>
            </table>
        </div>
    </div>

</template>

<script>
    import { mapState } from "vuex"
    import { friendlyDate, friendlyAmount } from "../../js/helpers"

    export default {
        computed: {
            ...mapState("app", {
                deposits: state => state.hasSessionData ? state.sessionData.deposits : []
            }),
            anyDeposits() {
                return this.deposits.length > 0;
            }
        },
        methods: {
            friendlyAmount,
            friendlyDate(deposit) {
                return friendlyDate(deposit.date);
            }
        }
    }
</script>

<style scoped>
    .deposits {
        text-align: left;
        font-size: 0.8em;
    }

    .deposit-list {
        width: 100%;
        border: 1px solid var(--orange-dark-1);
        word-break: break-all;
    }

    .alt {
        background-color: var(--orange-dark-1);
    }

    .amount {
    }

    .network {
    }

    .date {
        text-align: right;
    }

    .conf {
        text-align: center;
        color: var(--darkblue);
    }

    .pad-top {
        padding-top: 1em;
    }

    .pad-bottom {
        padding-bottom: 1em;
    }
</style>