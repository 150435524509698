<template>
    <header class="toast-header">
        <span v-if="!credited">Deposit in Progress</span>
        <span v-else>Deposit Complete</span>
    </header>
    <div>{{ friendlyAmount(data.amount) }} DOGE</div>
    <div>Via {{ data.networkName }} blockchain</div>
    <div v-if="!credited">Awaiting network confirmations</div>
</template>

<script>
    import { friendlyAmount } from "../../js/helpers"

    export default {
        props: {
            data: Object
        },
        computed: {
            credited() { return !!this.data.creditedDate; }
        },
        methods: {
            friendlyAmount
        }
    }
</script>
