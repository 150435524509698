<template>
    <Modal>
        <div class="send-from-ethereum">

            <ValuePanel :value="friendlyAmount(dogeBalance)" units="DOGE"></ValuePanel>

            <div v-if="hasDogeBalance">
                <div class="Padded">
                    <AmountInput :max="dogeBalance" v-model:amount="amount" v-model:amountIsValid="amountIsValid"></AmountInput>
                </div>
                <button :disabled="!canSend" @click="sendToBridge">Send Now</button>
                <div>
                    Credited within 3-6 network confirmations and controlled by your {{ network }} address
                    <span class="Address" :title="controllerAddress">{{ choppedAddress(controllerAddress) }}</span>
                </div>
            </div>
            <div v-else class="Padded">
                Your {{ network }} address
                <span class="Address" :title="controllerAddress">{{ choppedAddress(controllerAddress) }}</span>
                does not control any funds
            </div>
            <div v-if="waiting">{{ waiting }}</div>
            <div v-if="error" class="Error">{{ error }}</div>
        </div>
    </Modal>
</template>
<script>
    import Modal from "../Layouts/Modal"
    import { appActions } from "../../store/action-types"
    import { mapState } from "vuex";
    import { choppedAddress, friendlyAmount } from "../../js/helpers"
    import { ensureConnected, friendlyError } from "../../store/errorHelper"

    import ValuePanel from "../Widgets/ValuePanel"
    import AmountInput from "../Widgets/AmountInput"

    let self;
    async function handleCurrentState() {
        if (!self.walletConnected || !self.sessionConnected) {
            self.$store.dispatch(`app/${appActions.closeModal}`, null, { root: true });
        }
    }
    export default {
        components: {
            Modal,
            ValuePanel,
            AmountInput
        },
        data() {
            return {
                amount: "0",
                amountIsValid: false,
                error: "",
                waiting: ""
            };
        },
        computed: {
            ...mapState("contract", {
                dogeBalance: state => state.dogeBalance,
                hasDogeBalance: state => state.dogeBalance > 0
            }),
            ...mapState("session", {
                sessionConnected: state => state.connected,
            }),
            ...mapState("wallet", {
                walletConnected: state => state.connected,
                controllerAddress: state => state.address,
                rawNetwork: state => state.network
            }),
            network() { return this.rawNetwork || "Ethereum"; },
            canSend() { return this.amountIsValid && !this.waiting; }
        },
        watch: {
            walletConnected: handleCurrentState,
            sessionConnected: handleCurrentState
        },
        methods: {
            choppedAddress,
            friendlyAmount,
            async sendToBridge() {
                this.error = await ensureConnected(this);
                if (this.error) { return; }
                this.waiting = "Waiting for signature confirmation from your wallet...";
                try {
                    await this.$store.dispatch(`app/${appActions.sendToBridge}`, this.amount, { root: true });
                }
                catch (e) {
                    this.error = friendlyError(e, "Failed to transfer Doge");
                }
                this.waiting = "";
            }
        },
        mounted() {
            self = this;
            handleCurrentState();
        }
    }
</script>

<style scoped>
    .send-from-ethereum {
        text-align: center;
        max-width: 40em;
    }
</style>
