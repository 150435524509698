<template>
    <Modal>
        <div class="connect-wallet">
            <h2>Connect a Wallet</h2>
            <div class="connect-wallet-options">
                <button v-if="metamaskInstalled" @click="tryConnect(walletTypes.metamask)">Connect to Metamask</button>
                <a v-else href="https://metamask.io/download.html" target="_blank"><button>Install Metamask</button></a>
                <button @click="tryConnect(walletTypes.walletConnect)">Use WalletConnect<br />[Work in Progress!]</button>
            </div>
            <div v-if="waiting">{{ waiting }}</div>
            <div v-if="error" class="Error">{{ error }}</div>
        </div>
    </Modal>
</template>

<script>
    import Modal from "../Layouts/Modal"
    import { appActions, walletActions } from "../../store/action-types"
    import { mapActions, mapState } from "vuex";
    import { friendlyError } from "../../store/errorHelper"
    import walletTypes from "../../js/wallet-types"

    let self;

    async function handleCurrentState() {
        if (self.walletConnected) {
            await self.$store.dispatch(`app/${appActions.closeModal}`, null, { root: true });
        }
    }

    export default {
        components: {
            Modal
        },
        data() {
            return {
                error: "",
                waiting: "",
                walletTypes
            }
        },
        computed: {
            ...mapState("wallet", {
                walletConnected: state => state.connected,
                metamaskInstalled: state => state.metamaskInstalled
            }),
            connectingToWalletConnect() { return !!this.walletConnectUri; }
        },
        watch: {
            walletConnected: handleCurrentState,
            walletConnectUri: function () {
                this.updateQR();
            }
        },
        methods: {
            ...mapActions("wallet", {
                connect: walletActions.connect
            }),
            async tryConnect(walletType) {
                this.error = "";
                this.waiting = walletType == walletTypes.metamask ? "Metamask is waiting for you to unlock it" : "";
                try {
                    await this.connect({ walletType });
                }
                catch (e) {
                    this.error = friendlyError(e, "Could not connect to wallet");
                }
                this.waiting = "";
            }
        },
        mounted() {
            self = this;
            handleCurrentState();            
        }
    }
</script>

<style scoped>
    .connect-wallet {
        text-align: center;
    }
    .connect-wallet-options {
        display: grid;
        grid-gap: 1em;
        grid-auto-flow: column;
        padding-top: 1em;
    }
</style>