import { contractActions } from "../action-types"
import { getDogecoin, getEthersProvider } from "../../js/wallet"
import { utils } from "ethers"

let watchInProgress = false;
let lastWatch = 0;
let watchTimer = 0;

const state = () => ({
    dogeBalance: null,
    ethBalance: null,
    totalSupply: null
})

const mutations = {
    update(state, { dogeBalance, ethBalance, totalSupply }) {
        state.dogeBalance = utils.formatUnits(dogeBalance, 8);
        state.ethBalance = utils.formatEther(ethBalance);
        state.totalSupply = utils.formatUnits(totalSupply, 8);
    }
}

const actions = {
    [contractActions.watch](store) {
        watch(store);
    }
}

async function watch(store) {
    const { rootState, commit } = store;
    if (watchInProgress) { return; }    
    clearTimeout(watchTimer);
    const seconds = Date.now() - lastWatch;
    if (seconds < 5) {
        watchTimer = setTimeout(() => watch(store), (5 - seconds) * 1000);
        return;
    }
    if (rootState.wallet.connected) {
        try {
            const dogecoin = getDogecoin();
            const provider = getEthersProvider();
            const dogeBalance = await dogecoin.balanceOf(rootState.wallet.address, { blockTag: "pending" });
            const ethBalance = await provider.getBalance(rootState.wallet.address);
            const totalSupply = await dogecoin.totalSupply();
            commit("update", { dogeBalance, ethBalance, totalSupply });
            lastWatch = Date.now();
        }
        catch (e) {
            console.error(e);
        }
    }
    watchInProgress = false;
    watchTimer = setTimeout(() => watch(store), 60000);
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}