import { createStore, createLogger } from 'vuex'
import app from "./modules/app"
import connection from "./modules/connection"
import wallet from "./modules/wallet"
import contract from "./modules/contract"
import session from "./modules/session"
import toaster from "./modules/toaster"

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
    modules: {
        app,
        connection,
        wallet,
        contract,
        session,
        toaster
    },
    strict: debug,
    plugins: debug ? [createLogger()] : []
})