<template>
    <header class="header">
        <h1 class="title">
            Doge to
            <div class="overlap">
                <span :class="{ nope: isBinance }">Ethereum</span>
                <span v-if="isBinance">Binance</span>
            </div>
            Bridge
            <span v-if="!isBinance" class="subtext">(and Binance too!)</span>
        </h1>        
        <button v-if="!walletConnected" @click="openConnectWallet" class="btn">Connect Wallet</button>
        <button v-else-if="!sessionConnected" @click="autoSignIn" class="btn">Sign In</button>
    </header>
</template>

<script>
    import { networkChainIds } from "../env"
    import { mapActions, mapState } from "vuex";
    import { appActions } from "../store/action-types"
    import { choppedAddress } from "../js/helpers"

    export default {
        computed: {
            ...mapState("wallet", {
                walletConnected: state => state.connected,
                controllerAddress: state => state.address,
                chainId: state => state.chainId
            }),
            ...mapState("session", {
                sessionConnected: state => state.connected
            }),
            isBinance() { return this.chainId === networkChainIds["Binance"]; }
        },
        methods: {
            choppedAddress,
            ...mapActions("app", {
                openConnectWallet: appActions.openConnectWallet,
                openSignIn: appActions.openSignIn
            }),
            autoSignIn() { this.openSignIn({ auto: true }); }
        }
    }
</script>

<style scoped>
    .header {
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        grid-template-areas: ". title btn";
        background: var(--darkblue);
        align-items: center;
        justify-items: center;
        padding: 1em;
        text-align: center;
    }

    .title {
        grid-area: title;
    }

    .subtext {
        font-size: 0.375em;
        color: var(--yellow);
    }

    .btn {
        grid-area: btn;
        justify-self: end;
    }

    .nope {
        text-decoration: line-through;
        font-size: 0.4em;
        left: 50%;
        top: -0.25em;
        position: absolute;
        transform: translateX(-50%) rotate(-10deg);
        color: var(--yellow);
    }

    .overlap {
        position: relative;
        display: inline-block;
    }

    @media (max-width: 50em) {
        .header {
            grid-template-columns: 1fr;
            grid-template-areas: "title" "btn";
        }

        .btn {
            justify-self: center;
        }
    }

    @media (max-width: 40em) {
        .header {
            font-size: 0.8em;
        }
    }
</style>