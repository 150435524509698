<template>
    <button class="close" @click="onClick" aria-label="Close">X</button>
</template>

<script>
    export default {
        props: {
            onClick: Function
        }
    }
</script>

<style scoped>
    .close {
        width: 2em;
        height: 2em;
    }
</style>