import { walletActions, appActions } from "../action-types"
import { metamaskInstalled, getStatus, walletEvents, activateWallet, setWalletType } from "../../js/wallet"
import { currencySymbols, networkByChainId } from "../../env"

let inited = false;

const state = () => ({
    metamaskInstalled,
    connecting: false,
    connected: false,
    address: "",
    chainId: 0,
    nativeSymbol: "",
    network: ""
});

const mutations = {
    setDisconnected(state) {
        state.connecting = state.connected = false;
        state.address = "";
        state.chainId = 0;
        state.network = "";
        state.nativeSymbol = "";
    },
    setConnecting(state) {
        state.connecting = true;
        state.connected = false;
    },
    setConnected(state, { address, chainId }) {
        state.connecting = false;
        state.connected = true;
        state.address = address;
        state.chainId = chainId;
        state.network = networkByChainId[chainId];
        state.nativeSymbol = currencySymbols[chainId];
    }
}

const actions = {
    async [walletActions.connect](store, { walletType }) {
        const { commit } = store;
        setWalletType(walletType);
        commit("setConnecting");
        try {
            await activateWallet();
        }
        catch (e) {
            commit("setDisconnected");
            throw e;
        }
    },
    async [walletActions.init](store) {        
        if (inited) { return; }
        walletEvents.on("activate", () => handleAccountsChanged(store));
        walletEvents.on("chain", () => handleAccountsChanged(store));
        walletEvents.on("account", () => handleAccountsChanged(store));
        walletEvents.on("disconnect", () => handleAccountsChanged(store));
        walletEvents.on("connect", () => handleAccountsChanged(store));
        inited = true;
        handleAccountsChanged(store);
    }
}

async function handleAccountsChanged({ commit, dispatch }) {
    try {        
        const { address, chainId, connected } = await getStatus();
        if (!connected) {
            commit("setDisconnected");
            return;
        }
        commit("setConnected", { address, chainId });
        await dispatch(`app/${appActions.handleWalletConnected}`, { address, chainId }, { root: true });
    }
    catch (e) {
        commit("setDisconnected");
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}