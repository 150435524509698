<template>
    <div class="amount-input">
        <label :for="inputId" class="title"><slot>Amount</slot></label>
        <input class="input" :placeholder="placeholder" autocomplete="off" v-model="amountText" @input="inputNumbersOnly" :id="inputId" />
        <button class="max minimal" v-if="hasMax" @click="setMax">Max</button>
    </div>
</template>

<script>
    import { inputNumbersOnly, removeTrailingDecimals } from "../../js/helpers"
    import { utils } from "ethers"

    export default {
        data() {
            return {
                inputId: "",
                amountText: ""
            }
        },
        computed: {
            hasMax() { return this.max > 0; },
            isValid() {
                if (isNaN(parseFloat(this.amountText))) { return false; }
                const amt = utils.parseEther(this.amountText);
                if (amt.lte(0) && this.positiveOnly) { return false; }
                if (typeof this.max === "string" && !isNaN(parseFloat(this.max))) {
                    const maxAmt = utils.parseEther(this.max);
                    if (amt.gt(maxAmt)) { return false; }
                }
                return true;
            }
        },
        props: {
            placeholder: {
                type: String,
                default: "Enter an amount"
            },
            positiveOnly: {
                type: Boolean,
                default: true
            },
            max: String,
            amountIsValid: Boolean,
            amount: String
        },
        watch: {
            amountText(newAmount) {
                this.$emit("update:amount", newAmount);
                this.$emit("update:amountIsValid", this.isValid);
            }
        },
        methods: {
            inputNumbersOnly,
            setMax() {
                this.amountText = removeTrailingDecimals(this.max);
            }
        },
        beforeMount() {
            this.inputId = `amount-input-${Math.random()}`;
            if (this.amount) { this.amountText = this.amount.toString(); }
        }
    }
</script>

<style scoped>
    .amount-input {
        display: inline-grid;
        grid-template-areas: "title max" "input input";
        grid-template-columns: 1fr 3em;
        width: 20em;
        max-width: 95%;
    }

    .title {
        grid-area: title;
        justify-self: start;
    }

    .input {
        grid-area: input;
        justify-self: stretch;
    }

    .max {
        grid-area: max;
        justify-self: end;
    }
</style>