<template>
    <DogeBuilder :url="url" alt="Doge" :text="text"></DogeBuilder>
</template>

<script>
    import DogeBuilder from "./DogeBuilder"
    const Image = require("../../assets/vitalidoge.png")

    export default {
        data() {
            return {
                url: Image,
                text: [
                    {
                        words: "welcome to ethereon",
                        style: {
                            "max-width": "6em",
                            right: "3%",
                            bottom: "35%",
                            color: "var(--yellow)"
                        }
                    },
                    {
                        words: "smelly cat",
                        style: {
                            right: "20%",
                            bottom: "50%",
                            color: "var(--orange)"
                        }
                    },
                    {
                        words: "vary good boy",
                        style: {
                            left: "73%",
                            top: "6%",
                            "max-width": "4em",
                            color: "var(--offwhite)"
                        }
                    },
                    {
                        words: "nice leg",
                        style: {
                            right: "47%",
                            bottom: "27%",
                            color: "var(--red)"
                        }
                    },
                    {
                        words: "feel difrent",
                        style: {
                            left: "15%",
                            top: "33%",
                            color: "var(--green-dark-2)"
                        }
                    },
                    {
                        words: "try new thing",
                        style: {
                            left: "0%",
                            top: "47%",
                            color: "var(--lightblue)"
                        }
                    }
                ]
            }
        },
        components: {
            DogeBuilder
        }
    }
</script>