<template>

    <div class="ControlPanel">
        <h2>Sending from Bridge</h2>
        <div v-if="!anySends">
            No send instructions yet!
        </div>
        <div class="sends">
            <table class="send-list">
                <template v-for="(send, i) in sends" :key="send.instructionId">
                    <tr :class="{ alt: ~i&1 }">
                        <td class="pad-top">{{ friendlyAmount(send.amountToSend) }} DOGE</td>
                        <td class="date pad-top">{{ friendlyDate(send) }}</td>
                    </tr>
                    <tr :class="{ alt: ~i&1 }">
                        <td colspan="2" class="Address">{{ send.destinationAddress }}</td>
                    </tr>
                    <tr v-if="send.abortReason" :class="{ alt: ~i&1 }">
                        <td colspan="2">Aborted &amp; refunded: {{ send.abortReason }}</td>
                    </tr>
                    <tr v-else-if="send.transactionHash" :class="{ alt: ~i&1 }">
                        <td colspan="2"><a :href="explorerUrl(send)" target="_blank">View Transaction</a></td>
                    </tr>
                    <tr v-else-if="send.mintSignature && !send.transactionHash" :class="{ alt: ~i&1 }">
                        <td colspan="2">Not yet minted</td>
                    </tr>
                    <tr :class="{ alt: ~i&1 }">
                        <td colspan="2" class="network pad-bottom">{{ send.networkName }}</td>
                    </tr>
                </template>
            </table>
        </div>
    </div>

</template>

<script>
    import { mapState } from "vuex"
    import { friendlyDate, friendlyAmount, explorerUrl } from "../../js/helpers"

    export default {
        computed: {
            ...mapState("app", {
                sends: state => state.hasSessionData ? state.sessionData.sendInstructions : []
            }),
            anySends() {
                return this.sends.length > 0;
            }
        },
        methods: {
            friendlyAmount,
            explorerUrl(send) {
                return explorerUrl(send.transactionHash, send.networkName);
            },
            friendlyDate(send) {
                return friendlyDate(send.date);
            }
        }
    }
</script>

<style scoped>
    .sends {
        text-align: left;
        font-size: 0.8em;
    }

    .send-list {
        width: 100%;
        border: 1px solid var(--orange-dark-1);
        word-break: break-all;
    }

    .alt {
        background-color: var(--orange-dark-1);
    }

    .network {
    }

    .date {
        text-align: right;
    }

    .pad-top {
        padding-top: 1em;
    }

    .pad-bottom {
        padding-bottom: 1em;
    }
</style>