<template>
    <Modal>
        <div class="send-from-bridge">

            <ValuePanel :value="friendlyAmount(balance)" units="DOGE"></ValuePanel>

            <div v-if="hasBalance">
                <div class="Padded">
                    <AmountInput :max="balanceMinusFee" v-model:amount="amount" v-model:amountIsValid="amountIsValid"></AmountInput>
                </div>
                <div class="Padded">
                    <AddressInput :me="me" :network="network" v-model:address="destinationAddress" v-model:addressIsValid="destinationAddressIsValid">Destination Address</AddressInput>
                </div>
                <div v-if="fee">
                    Fee: {{ fee }} DOGE
                </div>
                <div v-if="fee && amountIsValid">
                    Total: {{ amountPlusFee }} DOGE
                </div>
                <div v-if="notDogecoin" class="Error">
                    Do not send directly to an exchange account
                    <button v-if="!showWhy" @click="why">Why?</button>
                </div>
                <div v-if="showWhy" class="why">
                    Most exchanges do not support this bridged dogecoin token. Send only to a wallet you control (using metamask, wallet connect, etc), or funds may be inaccessible!
                </div>
                <div v-if="showWhy" class="why">
                    To send to an exchange (like Binance), use the <button @click="bridgeToDoge">Send to Dogecoin Blockchain</button> button instead!
                </div>
                <button :disabled="!canSend" @click="sendDoge">Send to {{ network }}</button>
            </div>
            <div v-else class="Padded">
                There are no funds on the bridge controlled by your Ethereum address
                <span class="Address" :title="controllerAddress">{{ choppedAddress(controllerAddress) }}</span>
            </div>
            <div v-if="waiting">{{ waiting }}</div>
            <div v-if="error" class="Error">{{ error }}</div>
            <div v-if="differentNetwork" class="Error">Warning: You are not connected to {{ network }}<br />You cannot mint until you connect</div>
        </div>
    </Modal>
</template>
<script>
    import Modal from "../Layouts/Modal"
    import { appActions } from "../../store/action-types"
    import { mapState } from "vuex";
    import { choppedAddress, friendlyAmount } from "../../js/helpers"
    import { utils } from "ethers"
    import { ensureConnected, friendlyError } from "../../store/errorHelper"

    import ValuePanel from "../Widgets/ValuePanel"
    import AmountInput from "../Widgets/AmountInput"
    import AddressInput from "../Widgets/AddressInput"

    let self;
    async function handleCurrentState() {
        if (!self.walletConnected || !self.sessionConnected) {
            self.$store.dispatch(`app/${appActions.closeModal}`, null, { root: true });
        }
    }
    export default {
        components: {
            Modal,
            ValuePanel,
            AmountInput,
            AddressInput
        },
        props: {
            network: String
        },
        data() {
            return {
                amount: "",
                amountIsValid: false,
                destinationAddress: "",
                destinationAddressIsValid: false,
                waiting: "",
                error: "",
                showWhy: false
            };
        },
        computed: {
            ...mapState("app", {
                balance: state => state.hasSessionData ? state.sessionData.balance : 0,
                hasBalance: state => state.hasSessionData && state.sessionData.balance > 0
            }),
            ...mapState("session", {
                sessionConnected: state => state.connected,
            }),
            ...mapState("wallet", {
                walletConnected: state => state.connected,
                controllerAddress: state => state.address,
                connectedNetwork: state => state.network
            }),
            canSend() {
                return this.amountIsValid && this.destinationAddressIsValid && !this.waiting;
            },
            fee() {
                return this.network == "Dogecoin" ? 1 : 0;
            },
            me() {
                return this.network == "Ethereum" || this.network == "Binance" ? this.controllerAddress : null;
            },
            balanceMinusFee() {
                if (!this.fee) { return this.balance; }
                const val = utils.formatEther(utils.parseEther(this.balance).sub(utils.parseEther(this.fee.toString())));
                return val < 0 ? "0" : val;
            },
            amountPlusFee() {
                return friendlyAmount(utils.formatEther(utils.parseEther(this.amount).add(utils.parseEther(this.fee.toString()))));
            },
            differentNetwork() {
                return this.connectedNetwork != this.network && this.network != "Dogecoin";
            },
            notDogecoin() { return this.network !== "Dogecoin"; }
        },
        watch: {
            walletConnected: handleCurrentState,
            sessionConnected: handleCurrentState
        },
        methods: {
            choppedAddress,
            friendlyAmount,
            async sendDoge() {
                this.error = await ensureConnected(this);
                if (this.error) { return; }
                this.waiting = "Waiting for signature confirmation from your wallet...";
                try {
                    await this.$store.dispatch(`app/${appActions.sendDoge}`, { amount: this.amount, network: this.network, destinationAddress: this.destinationAddress }, { root: true });
                }
                catch (e) {
                    this.error = friendlyError(e, "Failed to send Doge");
                }
                this.waiting = "";
            },
            why() {
                this.showWhy = true;
            },
            async bridgeToDoge() {
                this.showWhy = false;
                await this.$store.dispatch(`app/${appActions.openSendToDogecoin}`, null, { root: true });
            },
        },
        mounted() {
            self = this;
            handleCurrentState();
        }
    }
</script>

<style scoped>
    .send-from-bridge {
        text-align: center;
        max-width: 40em;
    }
    .why {
        margin-top: 1em;
        margin-bottom: 1em;
    }
</style>
