const appActions = {
    handleGlobalStats: "handleGlobalStats",
    handleDogecoinAddress: "handleDogecoinAddress",
    handleSessionData: "handleSessionData",
    handleSendInstruction: "handleSendInstruction",
    handleBalance: "handleBalance",
    handleDeposit: "handleDeposit",
    handleVersion: "handleVersion",

    handleConnectionConnected: "handleConnectionConnected",
    handleConnectionDisconnected: "handleConnectionDisconnected",

    handleWalletConnected: "handleWalletConnected",

    tryStartCachedSession: "tryStartCachedSession",
    startSession: "startSession",
    assignDepositAddress: "assignDepositAddress",
    sendToBridge: "sendToBridge",
    sendDoge: "sendDoge",
    mint: "mint",
    mintAll: "mintAll",

    closeModal: "closeModal",
    openConnectWallet: "openConnectWallet",
    openSignIn: "openSignIn",
    openSendFromDogecoin: "openSendFromDogecoin",
    openSendFromEthereum: "openSendFromEthereum",
    openSendToDogecoin: "openSendToDogecoin",
    openSendToEthereum: "openSendToEthereum",
    openSendToBinance: "openSendToBinance",
    openXPub: "openXPub",
    openGlobalUnminted: "openGlobalUnminted"
}
const connectionActions = {
    maintainConnection: "maintainConnection",
    assignDogecoinAddress: "assignDogecoinAddress",
    getGlobalStats: "getGlobalStats",
    endSession: "endSession",
    startSession: "startSession",
    resumeSession: "resumeSession",
    sendDoge: "sendDoge"
}
const contractActions = {
    watch: "watch"
}
const walletActions = {
    connect: "connect",
    init: "init"
}
const sessionActions = {
    startSession: "startSession",
    endSession: "endSession",
    startCachedSession: "startCachedSession",
    sendDoge: "sendDoge",
    sendToBridge: "sendToBridge",
    mint: "mint",
    mintAll: "mintAll"
}
const toasterActions = {
    show: "show",
    remove: "remove"
}

export {

    appActions,
    connectionActions,
    contractActions,
    walletActions,
    sessionActions,
    toasterActions

}