<template>
    <div class="two-columns">
        <div class="one">
            <slot name="one"></slot>
        </div>
        <div class="two">
            <slot name="two"></slot>
        </div>
    </div>
</template>

<style scoped>
    .two-columns {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: "one two";
        justify-items: center;
        padding: 1em 0;
        max-width: 100em;
        margin: 0 auto;
    }

    @media (max-width: 63em) {
        .two-columns {
            grid-template-columns: 1fr;
            grid-template-areas: "one" "two";
            grid-gap: 1em;
        }
    }

    .one {
        grid-area: one;
    }
    .two {
        grid-area: two;
    }
</style>