<template>
    <div class="address-input">
        <label :for="inputId" class="title"><slot>Address</slot></label>
        <input class="input" :placeholder="placeholder" autocomplete="off" v-model="addressText" @input="inputAddressOnly" :id="inputId" />
        <button class="me minimal" v-if="hasMe" @click="setMe">Me</button>
    </div>
</template>

<script>
    import { inputAddressOnly, isEthereumAddress, isDogecoinAddress } from "../../js/helpers"

    export default {
        data() {
            return {
                inputId: "",
                addressText: ""
            }
        },
        computed: {
            hasMe() {
                return !!this.me;
            },
            isValid() {
                const isEthereum = isEthereumAddress(this.addressText);
                const isDogecoin = isDogecoinAddress(this.addressText);
                if (this.network == "Ethereum") { return isEthereum; }
                if (this.network == "Dogecoin") { return isDogecoin; }
                if (this.network == "Binance") { return isEthereum; }
                return isEthereum || isDogecoin;
            }
        },
        props: {
            placeholder: {
                type: String,
                default: "Enter an amount"
            },
            network: String,
            me: String,
            addressIsValid: Boolean,
            address: String
        },
        watch: {
            addressText(newAddress) {
                this.$emit("update:address", newAddress);
                this.$emit("update:addressIsValid", this.isValid);
            }
        },
        methods: {
            inputAddressOnly,
            setMe() {
                this.addressText = this.me;
            }
        },
        beforeMount() {
            this.inputId = "amount-input-" + Math.random();
            this.addressText = this.address;
        }
    }
</script>

<style scoped>
    .address-input {
        display: inline-grid;
        grid-template-areas: "title me" "input input";
        grid-template-columns: 1fr 3em;
        width: 20em;
        max-width: 95%;
    }
    .title {
        grid-area: title;
        justify-self: start;
    }
    .input {
        grid-area: input;
        justify-self: stretch;
    }
    .me {
        grid-area: me;
        justify-self: end;
    }
</style>