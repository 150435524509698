<template>

    <div class="ControlPanel">
        <h2>Addresses</h2>
        <div v-if="!anyAddresses">
            No addresses yet!
        </div>
        <div class="addresses">
            <table class="address-list">
                <tr v-if="anyAddresses" class="alt">
                    <th>Address</th>
                    <th class="tweak">XPub</th>
                </tr>
                <template v-for="(addr, i) in addresses" :key="addr.address">
                    <tr :class="{ alt: i%2 }">
                        <td class="Address pad-top pad-bottom">{{ addr.address }}</td>
                        <td class="tweak smaller pad-top pad-bottom">{{ addr.tweak }}</td>
                    </tr>
                </template>
            </table>
        </div>
    </div>

</template>

<script>
    import { mapState } from "vuex"

    export default {
        computed: {
            ...mapState("app", {
                addresses: state => state.hasSessionData ? state.sessionData.dogecoinAddresses : []
            }),
            anyAddresses() {
                return this.addresses.length > 0;
            }
        }
    }
</script>

<style scoped>
    .addresses {
        text-align: left;
    }

    .address-list {
        width: 100%;
        border: 1px solid var(--orange-dark-1);
        word-break: break-all;
    }

    .tweak {
        text-align: right;
    }

    .alt {
        background-color: var(--orange-dark-1);
    }

    .smaller {
        font-size: 0.8em;
    }

    .pad-top {
        padding-top: 1em;
    }

    .pad-bottom {
        padding-bottom: 1em;
    }
</style>