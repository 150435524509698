const signalR = require("@microsoft/signalr");
import { appActions, connectionActions } from "../action-types";
import { hubUrl } from "../../env"

let connection;
let maintainingConnection = false;
let reconnectInterval = 1;

function unwrap(wrapped) {
    if (wrapped.error) { throw wrapped.error; }
    return wrapped.result;
}

const state = () => ({
    connected: false,
    connecting: false
})

const mutations = {
    setDisconnected(state) {
        state.connected = state.connecting = false;
    },
    setConnecting(state) {
        state.connected = false;
        state.connecting = true;
    },
    setConnected(state) {
        state.connected = true;
        state.connecting = false;
    }
}

const actions = {
    async [connectionActions.assignDogecoinAddress]({ dispatch }) {
        dispatch(
            `app/${appActions.handleDogecoinAddress}`,
            {
                dogecoinAddress: unwrap(await connection.invoke("AssignDogecoinAddress"))
            },
            { root: true });
    },
    async [connectionActions.getGlobalStats]({ dispatch }) {
        dispatch(
            `app/${appActions.handleGlobalStats}`,
            unwrap(await connection.invoke("GetGlobalStats")),
            { root: true });
    },
    async [connectionActions.endSession]() {
        await connection.invoke("EndSession");
    },
    async [connectionActions.resumeSession]({ dispatch }, { sessionKey }) {
        dispatch(
            `app/${appActions.handleSessionData}`,
            {
                sessionKey,
                sessionData: unwrap(await connection.invoke("ResumeSession", sessionKey))
            },
            { root: true });
    },
    async [connectionActions.startSession]({ dispatch }, { controllerAddress, sessionKey, message, signature }) {
        dispatch(
            `app/${appActions.handleSessionData}`,
            {
                sessionKey,
                sessionData: unwrap(await connection.invoke("StartSession", controllerAddress, sessionKey, message, signature))
            },
            { root: true });
    },
    async [connectionActions.sendDoge]({ dispatch }, { amount, destinationAddress, network, fee, message, signature }) {
        dispatch(
            `app/${appActions.handleSendInstruction}`,
            {
                sendInstruction: unwrap(await connection.invoke("SendDoge", amount, destinationAddress, network, fee, message, signature))
            },
            { root: true });
    },
    [connectionActions.maintainConnection](store) {
        if (maintainingConnection) { return; }
        maintainingConnection = true;
        maintainConnectionCore(store);
    }
}

async function maintainConnectionCore(store) {
    const { dispatch, commit } = store;
    commit("setConnecting");
    try {
        if (connection) { connection.stop(); }
        connection = new signalR.HubConnectionBuilder().withUrl(hubUrl).build();
        connection.onclose(() => maintainConnectionCore(store));
        connection.on("GlobalStatsUpdate", globalStats => dispatch(`app/${appActions.handleGlobalStats}`, globalStats, { root: true }));
        connection.on("BalanceUpdate", (controllerAddress, balance) => dispatch(`app/${appActions.handleBalance}`, { controllerAddress, balance }, { root: true }));
        connection.on("DepositUpdate", deposit => dispatch(`app/${appActions.handleDeposit}`, { deposit }, { root: true }));
        connection.on("DogecoinAddressUpdate", dogecoinAddress => dispatch(`app/${appActions.handleDogecoinAddress}`, { dogecoinAddress }, { root: true }));
        connection.on("SendInstructionUpdate", sendInstruction => dispatch(`app/${appActions.handleSendInstruction}`, { sendInstruction }, { root: true }));
        connection.on("UseVersion", version => dispatch(`app/${appActions.handleVersion}`, version, { root: true }));
        await connection.start();
    }
    catch (ex) {
        commit("setDisconnected");
        setTimeout(() => maintainConnectionCore(store), reconnectInterval);
        reconnectInterval = reconnectInterval * 2 + 1000;
        if (reconnectInterval > 60000) { reconnectInterval = 60000; }
        await dispatch(`app/${appActions.handleConnectionDisconnected}`, null, { root: true });
        return;
    }

    commit("setConnected");
    reconnectInterval = 1;
    await dispatch(`app/${appActions.handleConnectionConnected}`, null, { root: true });
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}