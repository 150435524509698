<template>
    <DogeBuilder :url="url" alt="Doge" :text="text"></DogeBuilder>
</template>

<script>
    import DogeBuilder from "./DogeBuilder"
    const Image = require("../../assets/full-doge.png")

    export default {
        data() {
            return {
                url: Image,
                text: [
                    {
                        words: "same ol dorge",
                        style: {
                            right: "35%",
                            bottom: "10%",
                            color: "var(--red)"
                        }
                    },
                    {
                        words: "og",
                        style: {
                            left: "15%",
                            bottom: "25%",
                            color: "var(--darkblue)"
                        }
                    },
                    {
                        words: "wat happen over there?",
                        style: {
                            right: "0",
                            bottom: "70%",
                            color: "var(--yellow)"
                        }
                    },
                    {
                        words: "look like fun",
                        style: {
                            right: "15%",
                            bottom: "35%",
                            color: "var(--green)"
                        }
                    }
                ]
            }
        },
        components: {
            DogeBuilder
        }
    }
</script>