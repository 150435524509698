<template>
    <Modal>
        <div>
            These are {{ count }} transfer(s) to {{ network }} which have not yet been minted.
        </div>
        <div>
            <button @click="mintAll(network)" :disabled="count == 0">Mint All</button>
        </div>        
    </Modal>
</template>

<script>
    import { networkByChainId } from "../../env"
    import { mapActions, mapState } from "vuex"
    import { appActions } from "../../store/action-types"
    import Modal from "../Layouts/Modal"

    export default {
        components: {
            Modal
        },
        computed: {
            ...mapState("wallet", {
                chainId: state => state.chainId
            }),
            ...mapState("app", {
                globalStats: state => state.globalStats
            }),
            network() {
                return networkByChainId[this.chainId ? this.chainId : 1];
            },
            count() {
                return this.globalStats.networkStats[this.network.toLowerCase()].unmintedSendInstructions.length;
            }
        },
        methods: {
            ...mapActions("app", {
                mintAll: appActions.mintAll
            })
        }
    }
</script>

<style scoped>
</style>