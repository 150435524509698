<template>
    <section v-if="sessionConnected" class="history">
        <h2>Your History</h2>
        <ThreeColumns>
            <template #one>
                <TheDepositHistory></TheDepositHistory>
            </template>
            <template #two>
                <TheSendHistory></TheSendHistory>
            </template>
            <template #three>
                <TheAddressHistory></TheAddressHistory>
            </template>
        </ThreeColumns>
    </section>
</template>

<script>
    import ThreeColumns from "./Layouts/ThreeColumns"
    import TheDepositHistory from "./History/TheDepositHistory"
    import TheSendHistory from "./History/TheSendHistory"
    import TheAddressHistory from "./History/TheAddressHistory"
    import { mapState } from "vuex"

    export default {
        computed: {
            ...mapState("session", {
                sessionConnected: state => state.connected
            })
        },
        components: {
            ThreeColumns,
            TheDepositHistory,
            TheSendHistory,
            TheAddressHistory
        }
    }
</script>

<style scoped>
    .history {
        background-color: var(--green);
        text-align: center;
        padding: 1em 0;
    }
</style>