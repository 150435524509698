
import { createApp } from 'vue'
import App from './App.vue'

import store from "./store/index";

import DepositToast from "./components/Toasts/DepositToast"
import SendInstructionToast from "./components/Toasts/SendInstructionToast"

import { ApplicationInsights } from '@microsoft/applicationinsights-web'

const app = createApp(App);
app.component("deposit-toast", DepositToast);
app.component("send-instruction-toast", SendInstructionToast);
app.use(store);
app.mount('#app')

const insights = new ApplicationInsights({
    config: {
        connectionString: "InstrumentationKey=29fb900e-2c6b-455e-8b63-8ce9f53aa463;IngestionEndpoint=https://canadacentral-0.in.applicationinsights.azure.com/"
    }
});
insights.loadAppInsights();
insights.trackPageView();